export const header: Array<string> = ['Hej!, mam na imię Paweł', 'jestem, programistą'];

export const phrases: Array<string> = ["JavaScript", "TypeScript", "Node.js", "SQL", "NoSQL", "Go lang"];

export const links: Array<{ href: string, title: string }> = [
    {href: "/#portfolio", title: "portfolio"},
    {href: "/#about", title: "o mnie"},
    {href: "https://polski.dev", title: "blog"},
    {href: "/contact", title: "kontakt"}
];
