import type {ReactElement} from "react";
import {Link} from "@uxu/design-system/components/atoms/link";
import {TextScramble} from "@uxu/design-system/components/atoms/textScrambleEffect";
import styles from './styles.module.scss';
import {links, header, phrases} from './consts';

export function SectionHello(): ReactElement {
    return (
        <section className={styles.section} style={{backgroundImage: "url(./img/face.png)"}}>
            <div className={styles.headerWithButtonContainer}>
                <p>{header[0]}</p>
                <h1>{header[1]}</h1>
                <TextScramble
                    option={{ time: 3000, pauseTime: 3000 }}
                    phrases={phrases}
                />
                <div className={styles.wrapperButtons}>
                    <Link
                        className="btn special large"
                        href="/contact"
                        title="Masz pytania?"
                    >
                        <span/><span/><span/>
                        Masz pytania?
                    </Link>
                </div>
            </div>
            <ul className={styles.listLinks}>
                {links.map((link) => (
                    <li key={link.href}>
                        <Link href={link.href} title={link.title}>{link.title}</Link>
                    </li>
                ))}
            </ul>
        </section>
    );
}