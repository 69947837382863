import type {ReactElement} from "react";
import {Table} from "@uxu/design-system/components/atoms/table";
import type {RecordListProps} from "./types";
import styles from "./styles.module.scss";


export function RecordList({columns, data, children}: RecordListProps): ReactElement {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperData}>
                {children}
                <Table columns={columns} data={data}/>
            </div>
        </div>
    )
}