import {vectorBrands} from "@uxu/assets";
import chamsko from '../../../../../public/icons/brand/chamsko.png'
import type {PortfolioItem} from './type';

export const historyItems: Array<PortfolioItem> = [
    {
        id: "uxu",
        typ: ['uxu'],
        title: "UXU - Full-stack Developer",
        description: 'Pracuję nad projektem telbook.info, który zarządza bazą danych zawierającą ponad 180 milionów numerów telefonów w Polsce. Jako full-stack developer wykorzystuję technologie takie jak Go, Redis, PostgreSQL oraz Next.js, aby tworzyć skalowalne i wydajne rozwiązania. Zajmuję się także konfiguracją środowisk przy użyciu Kubernetes i Dockera oraz automatyzuję procesy wdrażania z użyciem CI/CD. Równocześnie realizuję mniejsze projekty jako freelancer, łącząc kompleksowy rozwój oprogramowania z elastycznymi zadaniami dostosowanymi do potrzeb klientów.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.uxu,
        }
    },
    {
        id: "grenton",
        typ: ['grenton'],
        title: "Grenton - Full-stack Developer",
        description: 'W Grenton byłem odpowiedzialny za współtworzenie systemu do konfiguracji inteligentnych domów, opartego na technologiach React.js, Electron.js i Node.js. System umożliwiał użytkownikom graficzne tworzenie logiki działania inteligentnego domu, co pozwalało na łatwe definiowanie zasad i akcji. Generowany kod w języku Lua był przesyłany do urządzeń zarządzających, które sterowały automatyką domową.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.grenton,
        }
    },
    {
        id: "wp",
        typ: ['wp'],
        title: "Wirtualna Polska - Frontend Web Developer",
        description: 'Jako Front-End Developer rozwijałem warstwę front-end serwisów o dużym ruchu, takich jak wiadomosci.wp.pl oraz kobieta.wp.pl. Byłem zaangażowany w współtworzenie autorskiej platformy front-endowej opartej na Node.js z wykorzystaniem SSR (Server-Side Rendering). Dodatkowo, wspierałem wdrożenie systemu live blogów opartego na Server-Side Events (SSE) dla portalu wiadomości. Moje obowiązki obejmowały również optymalizację front-endu, konfigurację serwerów Docker i Nginx, a także pracę w środowisku agile z wykorzystaniem narzędzi takich jak Confluence, Jira i Grafana.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.wp,
        }
    },
    {
        id: "autentika",
        typ: ['autentika'],
        title: "Autentika - Frontend Web Developer",
        description: 'W ramach body leasingu przez Autentikę, byłem oddelegowany do projektów w Wirtualnej Polsce. Oprócz realizacji zadań związanych z programowaniem, brałem udział w warsztatach UX, co pozwoliło mi lepiej zrozumieć interfejsy użytkownika i podnieść moje kompetencje jako deweloper. Rozwinąłem również umiejętności w zakresie tworzenia grafik wektorowych w Figmie oraz optymalizowałem stronę firmową Autentiki pod kątem wydajności.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.autentika,
        }
    },
    {
        id: "freelancer2",
        typ: ['freelancer'],
        title: "Freelancer - Frontend Web Developer",
        description: 'W ramach działalności freelancerskiej koncentruję się na projektach opartych o React.js, rozwijając swoje umiejętności w technologiach takich jak Node.js, MongoDB oraz PostgreSQL. Moim celem jest dalszy rozwój w kierunku full-stack developmentu oraz zdobycie biegłości w narzędziach konteneryzacyjnych, takich jak Docker.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.freelancer,
        }
    },
    {
        id: "plaincoded",
        typ: ['plaincoded'],
        title: "Plaincoded - Frontend Web Developer",
        description: 'Pracowałem nad stronami zbudowanymi na Gatsby.js, które były bogate w interaktywne animacje, podkreślające ekskluzywność luksusowych nieruchomości w Londynie. Moja praca koncentrowała się na płynności animacji i dostosowaniu interfejsu do wymagań wymagającej grupy docelowej.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.plaincoded,
        }
    },
    {
        id: "freelancer1",
        typ: ['freelancer'],
        title: "Freelancer - Frontend Web Developer",
        description: 'W 2016 roku, po kilkuletniej przerwie spowodowanej fascynacją SEO, marketingiem i zarządzaniem, wróciłem do programowania, koncentrując się na JavaScript i tworząc proste strony w WordPressie ponieważ już wcześniej programowałem w PHP. Z czasem zacząłem pracować dla klientów takich jak PAGE Interactive, HL Tech, Quarticon i K2 Precise, realizując dla nich projekty oparte na WordPressie. Każdy projekt był dla mnie krokiem naprzód w doskonaleniu warsztatu i zrozumieniu procesu tworzenia stron.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.freelancer,
        }
    },
    {
        id: "chamsko",
        typ: ['chamsko'],
        title: "Chamsko.pl - Full-stack Developer",
        description: 'W 2013 roku stworzyłem i rozwijałem serwis chamsko.pl, który zgromadził 200 000 użytkowników dziennie. Serwis został w całości napisany przeze mnie w PHP.',
        url: 'string',
        logo: {
            typ: 'img',
            image: chamsko,
            sizes: {
                width: 200,
                height: 50
            }
        }
    },
    {
        id: "seo",
        typ: ['seo'],
        title: "Agencja SEO",
        description: 'Pod koniec 2011 roku założyłem agencję SEO, koncentrując się początkowo na serwisach rozrywkowych. Dzięki doświadczeniu programistycznemu szybko stałem się ekspertem SEO, zarządzając zespołami copywriterów i pozycjonerów oraz koordynując działania związane z optymalizacją stron i link buildingiem.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.na3,
        }
    },
    {
        id: "cashfly",
        typ: ['cashfly'],
        title: "Cashfly - Full-stack Developer",
        description: 'W 2011 roku założyłem NetworkCenter Sp. z o.o., tworząc sieć afiliacyjną koncentrującą się na dystrybucji gier przeglądarkowych. Projekt przyciągnął ponad 1 200 stron internetowych i wygenerował kilka milionów odsłon miesięcznie. W ramach projektu zdobyłem doświadczenie w projektowaniu architektur opartych na load balancingu.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.cashfly,
        }
    },
    {
        id: "start",
        typ: ['start'],
        title: "Początki - Web Developer",
        description: 'W 2007 roku, jako hobby, uruchomiłem swoją pierwszą stronę internetową opartą na DataLife Engine (DLE), na której zbudowałem społeczność wymieniającą się wiedzą na temat frameworków, CMS-ów i narzędzi webowych. W tamtych czasach GitHub jeszcze nie istniał, więc platformy tego typu były niezbędne dla twórców stron internetowych.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.start,
        }
    },
]