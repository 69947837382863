import { type ReactElement, useEffect, useState } from "react";
import Image from "next/image";
import { Link } from "@uxu/design-system/components/atoms/link";
import { portfolioItems } from './consts';
import type { PortfolioItem } from "./type";
import styles from "./styles.module.scss";

export function Portfolio(): ReactElement {
    const [items, setItems] = useState<Array<PortfolioItem>>(portfolioItems);
    const [sort, setSort] = useState<'web' | 'mobile' | 'desktop' | 'all'>('all');

    useEffect(() => {
        const newItems = sort === 'all' ? portfolioItems : portfolioItems.filter(item =>
            item.typ.includes(sort)
        );

        setItems(newItems);
    }, [sort]);

    return (
        <section className={styles.section} id="portfolio">
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.menu}>
                        <h4>#Portfolio</h4>
                        {['all', 'web', 'mobile'].map(category => (
                            <button
                                className={sort === category ? styles.active : ""}
                                key={category}
                                onClick={() => {
                                    setSort(category as 'web' | 'mobile' | 'desktop' | 'all')
                                }}
                                type="button"
                            >
                                {category === 'all' ? 'wszystko' : `aplikacje ${category}`}
                            </button>
                        ))}
                    </div>
                </div>

                <div className={`${styles.col} ${styles.content}`}>
                    {items.map((item) => (
                        <div className={styles.card} key={item.id}>
                            <div className={`${styles.face} ${styles.faceFront}`}>
                                {item.logo.typ === 'svg' ? (
                                    item.logo.source
                                ) : (
                                    <Image
                                        alt={item.id}
                                        height={item.logo.sizes.height}
                                        src={item.logo.image}
                                        width={item.logo.sizes.width}
                                    />
                                )}
                            </div>
                            <div className={`${styles.face} ${styles.faceBack}`}>
                                <p>{item.description}</p>
                                {Boolean(item.url) && <Link className="btn primary" href={item.url || "/"} title="szczegóły">szczegóły</Link>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}