import { type ReactElement, useEffect, useState, useRef, useCallback } from "react";
import Image from "next/image";
import { historyItems } from './consts';
import styles from "./styles.module.scss";

export function History(): ReactElement {
    const [activeCategory, setActiveCategory] = useState<string>("uxu");
    const itemRefs = useRef<Array<HTMLDivElement | null>>([]);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const updateCategory = useCallback((id: string) => {
        if (id !== activeCategory) {
            setActiveCategory(id);
        }
    }, [activeCategory]);

    useEffect(() => {
        if (observerRef.current) return;

        observerRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const id = entry.target.getAttribute("data-id");
                        if (id) {
                            updateCategory(id); // Zaktualizuj kategorię
                        }
                    }
                });
            },
            {
                root: null,
                threshold: 0,  // Element jest "widziany" gdy tylko dotknie górnej krawędzi
                rootMargin: '-50% 0px -50% 0px', // Element musi dotknąć górnej krawędzi widoku
            }
        );

        const observer = observerRef.current;

        itemRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            itemRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [updateCategory]);

    const handleScrollTo = (id: string) => {
        const index = historyItems.findIndex(item => item.id === id);
        if (itemRefs.current[index]) {
            itemRefs.current[index]?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
        updateCategory(id);
    };

    return (
        <section className={styles.section} id="about">
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.menu}>
                        <h4>#Historia</h4>
                        {[{id: "uxu", name: "uxu"}, {id:"grenton", name: "grenton"}, {id:"wp", name: "wirtualna polska"}, {id:"autentika", name: "autentika"}, {id:"freelancer2", name: "freelancer"}, {id:"plaincoded", name: "plaincoded"}, {id:"freelancer1", name: "freelancer"}, {id:"chamsko", name: "chamsko"}, {id:"seo", name: "seo"}, {id:"cashfly", name: "cashfly"}, {id:"start", name: "start"}].map(category => (
                            <button
                                className={activeCategory === category.id ? styles.active : ""}
                                key={category.id}
                                onClick={() => { handleScrollTo(category.id); }}
                                type="button"
                            >
                                {category.name}
                            </button>
                        ))}
                    </div>
                </div>

                <div className={`${styles.col} ${styles.content}`}>
                    {historyItems.map((item, index) => (
                        <div
                            className={styles.card}
                            data-id={item.id}
                            key={item.id}
                            ref={(el) => { itemRefs.current[index] = el; }}
                        >
                            <div>
                                {item.logo.typ === 'svg' ? (
                                    item.logo.source
                                ) : (
                                    <Image
                                        alt={item.id}
                                        height={item.logo.sizes.height}
                                        src={item.logo.image}
                                        width={item.logo.sizes.width}
                                    />
                                )}
                            </div>
                            <div className={styles.text}>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}