import {vectorBrands} from "@uxu/assets";
import chamsko from '../../../../../public/icons/brand/chamsko.png'
import type {PortfolioItem} from './type';

export const portfolioItems: Array<PortfolioItem> = [
    {
        id: "wpwiadomosci",
        typ: ['all', 'web'],
        description: 'Pracowałem jako programista dla Wirtualnej Polski przy projekcie wiadomości.wp.pl',
        logo: {
            typ: 'svg',
            source: vectorBrands.wpwiadomosci,
        }
    },
    {
        id: "chamsko",
        typ: ['all', 'web'],
        description: 'Po pracy hobbistycznie stworzyłem serwis chamsko.pl, który obecnie odwiedza 200 000 użytkowników dziennie.',
        logo: {
            typ: 'img',
            image: chamsko,
            sizes: {
                width: 200,
                height: 50
            }
        }
    },
    {
        id: "hltech",
        typ: ['all', 'web'],
        description: 'Wykonałem stronę firmową dla HLTech, będącego częścią Hargreaves Lansdown.',
        logo: {
            typ: 'svg',
            source: vectorBrands.hltech,
        }
    },
    {
        id: "wpkobieta",
        typ: ['all', 'web'],
        description: 'Pracowałem jako programista dla Wirtualnej Polski przy projekcie kobieta.wp.pl',
        logo: {
            typ: 'svg',
            source: vectorBrands.wpkobieta,
        }
    },
    {
        id: "wTrasie",
        typ: ['all', 'web'],
        description: 'Stworzyłem serwis informacyjny dla ogólnopolskiej sieci wulkanizacji mobilnej.',
        logo: {
            typ: 'svg',
            source: vectorBrands.wTrasie,
        }
    },
    {
        id: "admin",
        typ: ['all', 'web'],
        description: 'Stworzyłem system do zarządzania mikroserwisami w Go oraz React.js.',
        logo: {
            typ: 'svg',
            source: vectorBrands.admin,
        }
    },
    {
        id: "polskidev",
        typ: ['all', 'web'],
        description: 'Hobbistycznie stworzyłem blog, na którym piszę o programowaniu i innych tematach.',
        logo: {
            typ: 'svg',
            source: vectorBrands.polskidev,
        }
    },
    {
        id: "aua",
        typ: ['all', 'web', 'desktop', 'mobile'],
        description: 'Stworzyłem serwis wykorzystujący AI do analizy i wykrywania błędów prawnych w umowach.',
        logo: {
            typ: 'svg',
            source: vectorBrands.aua,
        }
    },
    {
        id: "telbook",
        typ: ['all', 'web'],
        description: 'Stworzyłem największą książkę telefoniczną w Polsce, zawierającą informacje o ponad 180 milionach numerów telefonów.',
        logo: {
            typ: 'svg',
            source: vectorBrands.telbook,
        }
    }
]