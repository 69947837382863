import React, { useRef, useState, useEffect, type ReactElement } from "react";
import type { TextScrambleProps } from "./types";
import styles from "./styles.module.scss";

export function TextScramble({ phrases, option = { time: 1000, pauseTime: 2000 } }: TextScrambleProps): ReactElement {
    const { time, pauseTime } = option;
    const span = useRef<HTMLDivElement>(null);
    const [activePhrase, setActivePhrase] = useState(0);
    const chars = "____----`!@#$%^&*()_+=~/?><}{[]";

    // Helper function to calculate the length of the longest word.
    const getWordLength = (current: number, previous: number): number =>
        Math.max(phrases[current]?.length || 0, phrases[previous]?.length || 0);

    // Queue generation logic moved outside useEffect for better readability.
    const generateQueue = (lastPhrase: number, wordLength: number) =>
        Array.from({ length: wordLength }, (_, i) => {
            const from = phrases[lastPhrase]?.[i] || "";
            const to = phrases[activePhrase]?.[i] || "";
            const start = Math.floor(Math.random() * 40);
            const end = start + Math.floor(Math.random() * 40);
            return { from, to, start, end };
        });

    useEffect(() => {
        let startFrame: number | null = null;
        const lastPhrase = activePhrase === 0 ? phrases.length - 1 : activePhrase - 1;
        const wordLength = getWordLength(activePhrase, lastPhrase);
        const queue = generateQueue(lastPhrase, wordLength);
        const maxEnd = Math.max(...queue.map((shot) => shot.end));

        const update = (timestamp: number) => {
            if (!startFrame) startFrame = timestamp;
            const progress = timestamp - startFrame;

            const output = queue
                .map(({ from, to, start, end }) => {
                    const animationProgress = (progress / time) * maxEnd;
                    if (animationProgress >= end) {
                        return to;
                    } else if (animationProgress >= start) {
                        const randomChar = chars[Math.floor(Math.random() * chars.length)];
                        return `<span>${randomChar}</span>`;
                    }
                    return from;
                })
                .join("");

            if (span.current) span.current.innerHTML = output;
            if (progress < time) {
                requestAnimationFrame(update);
            }
        };

        requestAnimationFrame(update);

        return () => {
            startFrame = null; // Reset frame for the next animation
        };
    }, [activePhrase, phrases, time]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActivePhrase((prevPhrase) => (prevPhrase + 1) % phrases.length);
        }, time + pauseTime);

        return () => {
            clearInterval(interval);
        };
    }, [time, pauseTime, phrases]);

    return <div className={styles.textScrambleSign} ref={span} />;
}

export type TextScrambleType = {
    option?: {
        time: number;
        pauseTime: number;
    };
    phrases: string[];
};